import React, {useEffect, useMemo, useState} from 'react';
import Slider from 'react-slick';
import BannerProperty from "../../components/banner/BannerProperty";
import {PropertyListItem} from "./models/property-list-item";
import PropertyListItemCardRelated from "../../components/property-card/PropertyListItemCardRelated";
import {fetchLastProperties, fetchProperty, Property} from "../../services/propertyService";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import parse from 'html-react-parser';
import Contact from "../../components/contact/Contact";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

function SampleNextArrow(props:any) {
  const { className, style, onClick } = props;
  return (
      <div className={className} style={{ ...style, right: 10, zIndex: 50 }} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white bg-black rounded-full p-2" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"/>
        </svg>
      </div>
  );
}

function SamplePrevArrow(props:any) {
  const { className, style, onClick } = props;
  return (
      <div className={className} style={{ ...style, left: -10, zIndex: 50, overflow: "visible" }} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white bg-black rounded-full p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"/>
        </svg>
      </div>
  );
}

const propertiesToListItems = (properties: Property[], language: string): PropertyListItem[] => {
  return properties.map(property => {
    return {
      id: property.id,
      name: property.attributes.name,
      extract: property.attributes.extract[language],
      description: property.attributes.description[language],
      slug: property.attributes.slug,
      operation: property.attributes.property_operation[language],
      price: property.attributes.price,
      property_size: property.attributes.property_size,
      property_size_useful: property.attributes.property_size_useful,
      property_size_plot: property.attributes.property_size_plot,
      bedrooms: property.attributes.bedrooms,
      bathrooms: property.attributes.bathrooms,
      garages: property.attributes.garages,
      imageUrl: property.attributes.banner_image,
    };
  });
}

const propertyItems = (property: Property, language: string): PropertyListItem => {
  return {
      id: property.id,
      name: property.attributes.name,
      description: property.attributes.description[language],
      slug: property.attributes.slug,
      ref: property.attributes.property_id,
      property_status: property.attributes.property_status[language],
      operation: property.attributes.property_operation[language],
      price: property.attributes.price,
      property_size: property.attributes.property_size,
      property_size_useful: property.attributes.property_size_useful,
      property_size_plot: property.attributes.property_size_plot,
      bedrooms: property.attributes.bedrooms,
      bathrooms: property.attributes.bathrooms,
      garages: property.attributes.garages,
      property_address: property.attributes.property_address,
      features: property.attributes.property_features[language],
      imageUrl: property.attributes.banner_image,
      mediaGallery: property.attributes.media_gallery
  };
}

const PropertyDetailView = () => {
  const {t, i18n} = useTranslation();
  const {year, month, day, slug} = useParams();
  const [property, setProperty] = useState<PropertyListItem>();
  const [rawProperty, setRawProperty] = useState<Property>();
  const [properties, setProperties] = useState<Array<PropertyListItem>>([]);
  const [rawProperties, setRawProperties] = useState<Array<Property>>([]);
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  const propertyParams = useMemo(() => {
    return {
      year: parseInt(`${year}`),
      month: parseInt(`${month}`),
      day: parseInt(`${day}`),
      slug: `${slug}`,
    };
  }, [year, month, day, slug]);

  useEffect(() => {
    fetchProperty(propertyParams)
        .then(setRawProperty);
  }, [propertyParams]);

  useEffect(() => {
    if(rawProperty) {
      setProperty(propertyItems(rawProperty, i18n.resolvedLanguage));
    }
  }, [rawProperty, i18n.resolvedLanguage]);

  useEffect(() => {
    fetchLastProperties()
        .then(setRawProperties);
  }, []);

  useEffect(() => {
    setProperties(propertiesToListItems(rawProperties, i18n.resolvedLanguage));
  }, [rawProperties, i18n.resolvedLanguage]);

  const settings = {
    autoplay:true,
    autoplaySpeed:5000,
    centerMode: false,
    speed:500,
    variableWidth: false,
    vertical: false,
    infinite: true,
    slidesToShow:1,
    slidesToScroll:1,
    pauseOnHover:false,
    dots:true,
    pauseOnDotsHover:false,
    cssEase:'linear',
    fade:true,
    draggable:false,
    arrows: false,
    zIndex: 1,
    asNavFor: '.slider-nav'
  };

  const settingsThumbs = {
    slidesToScroll: 1,
    dots: false,
    centerPadding: '10px'
  };

  const settingsRelated = {
    autoplay:true,
    autoplaySpeed:5000,
    centerMode: false,
    speed:500,
    variableWidth: false,
    vertical: false,
    infinite: true,
    slidesToShow:3,
    slidesToScroll:3,
    pauseOnHover:false,
    dots:false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  }

  function commify(n:any) {
    if(n) {
      let parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    } else {
      return 0;
    }
  }

  return (
      <HelmetProvider>
        <Helmet>
          <title>{`Reesman | ${property?.name}`}</title>
          <meta name='description' content={property?.extract} />
          <meta name='robots' content='index,follow' />
          <link rel='canonical' href={currentURL} />
          {getSupportedLanguages().map(lang => {
            return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}/${segments[5]}/${segments[6]}/${segments[7]}/${segments[8]}`} />
          })}
        </Helmet>
        <BannerProperty settings={settings} settingsThumbs={settingsThumbs} mediaGallery={property?.mediaGallery || []} />
        <section className="max-w-6xl mx-auto pt-14 pb-32 px-4">
            <div className="property-data py-6">
              <div className="flex flex-col items-start lg:flex-row lg:justify-between lg:items-center">
                <h1 className="title__h1 text-center lg:text-left text-3xl tracking-tight">{property?.name}</h1>
                <h2 className="title__h1 text-3xl tracking-tight bg-black bg-opacity-90 mt-2 lg:mt-0 text-white py-3 px-4 rounded-lg">
                  {property?.price ? commify(property?.price) : t('card-properties.consult')} {property?.price ? '€' : ''}
                </h2>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 items-start gap-10">
                <div className="lg:col-span-2">
                  <div className="flex flex-col justify-start mt-6 space-y-1.5">
                    <div className="flex flex-row">
                      <h3 className="uppercase">{t('property-detail.property-id')}</h3>: {property?.ref}
                    </div>
                    <div className="flex flex-row">
                      <h3 className="uppercase">{t('property-detail.property-status')}</h3>: {property?.property_status}
                    </div>
                    <div className="flex flex-row">
                      <h3 className="uppercase">{t('property-detail.property-bedrooms')}</h3>: {property?.bedrooms}
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <h3 className="uppercase">{t('property-detail.property-size')}</h3>: {property?.property_size} m<sup>2</sup>
                    </div>
                    <div className="flex flex-row">
                      <h3 className="uppercase">{t('property-detail.property-bathrooms')}</h3>: {property?.bathrooms}
                    </div>
                    <div className="flex flex-row">
                      <h3 className="uppercase">{t('property-detail.property-address')}</h3>: {property?.property_address}
                    </div>
                  </div>

                  <div className="property-description py-10">
                    <h1 className="title__h1 text-left text-3xl tracking-tight">{t('property-detail.property-description')}</h1>
                    <div className="mt-6">
                      {parse(`${property?.description}`)}
                    </div>
                  </div>
                  <div className="property-features py-10">
                    <h1 className="title__h1 text-left text-3xl tracking-tight">{t('property-detail.features')}</h1>
                    {property?.features &&
                        <ul className="list-disc list-inside mt-6">
                          {property.features.map(feature =>
                              <li key={feature}>
                                {feature}
                              </li>
                          )}
                        </ul>
                    }
                  </div>
                </div>
                <div className="bg-black bg-opacity-90 relative px-6 py-3 rounded-3xl col-span-1 mt-6 shadow-2xl">
                  <Contact property={property!} />
                </div>
              </div>
            </div>
        </section>
        {properties.length &&
            <section className="max-w-6xl mx-auto pb-20">
              <h1 className="title__h1 text-center lg:text-left text-3xl tracking-tight">{t('property-detail.related-properties')}</h1>
              <div className="container mx-auto">
                <Slider {...settingsRelated} className="flex flex-wrap lg:-mx-4 slider-related">
                  {properties.slice(0, 4).map(property =>
                      <PropertyListItemCardRelated key={property.id}
                                                   property={property} />
                  )}
                </Slider>
              </div>
            </section>
        }
      </HelmetProvider>
  );
}

export default PropertyDetailView;
