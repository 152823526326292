import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Data,
    getPropertiesStatus,
    PropertyStatusAttributes,
    sendContact,
    verifyToken
} from "../../services/dataService";
import {CAPTCHA_SITE_KEY} from '../../constants';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";

declare global {
    interface Window {
        captchaOnLoad: () => void,
        grecaptcha: ReCaptchaInstance
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => any,
    execute: (siteKey: string|undefined, options: ReCaptchaExecuteOptions) => Promise<string>,
    render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
    action: string
}

interface ReCaptchaRenderOptions {
    sitekey: string,
    size: 'invisible'
}

interface FormDataElement {
    key: string;
    value: string;
}

const convertPropertyStatus = (list: Data<PropertyStatusAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.filter(propertyStatus => {
        const slug = propertyStatus.attributes.slug.toLowerCase();
        return !slug.includes("sale");
    }).map(propertyStatus => {
        let translatedValue = null;

        for (const translation of propertyStatus.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyStatus.attributes.slug;
        }

        return {
            key: propertyStatus.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [...result];
}

const validate = (elements:any) => {
    const { _my_name_honeypot, name, phone, email, description } = elements;
    const errors = [];

    if (_my_name_honeypot.value !== '') {
        errors.push("No eres un humano");
    }
    if (name.value.length < 3) {
        errors.push("El nombre debe tener al menos 3 caracteres");
    }
    if (phone.value.length < 8) {
        errors.push("El teléfono debe contener al menos 5 caracteres");
    }
    if (email.value.length < 5) {
        errors.push("El email debe contener al menos 5 caracteres");
    }
    if (email.value.split("").filter((x:any) => x === "@").length !== 1) {
        errors.push("Introduzca una email válido");
    }
    if (email.value.indexOf(".") === -1) {
        errors.push("Introduzca una email válido");
    }
    if (description.value.length < 10) {
        errors.push("La descripción debe contener al menos 10 caracteres");
    }

    return errors;
}

const SectionContact = () => {
    const {t, i18n} = useTranslation();
    const [buttonText, setButtonText] = useState('');
    const [errors, setErrors] = useState<string[]>();
    const [messageSend, setMessageSend] = useState('');
    const [messageError, setMessageError] = useState('');
    const [isRenting, setIsRenting] = useState(false);
    const [propertyStatusList, setPropertyStatusList] = useState<Data<PropertyStatusAttributes>[]>([]);
    const [propertyStatusFormData, setPropertyStatusFormData] = useState<FormDataElement[]>([]);
    const currentLanguage = i18n.resolvedLanguage;

    useEffect(() => {
        getPropertiesStatus()
            .then(setPropertyStatusList);
    }, []);

    useEffect(() => {
        Promise.resolve(propertyStatusList)
            .then(list => convertPropertyStatus(list, i18n.resolvedLanguage, t))
            .then(setPropertyStatusFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyStatusList]);

    useEffect(() => {
        setButtonText(t('contact-section.send-button'));
    }, [i18n.resolvedLanguage])

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsRenting(e.target.checked);
    };

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        setMessageSend('');
        setMessageError('');

        const errors = validate(e.target.elements);
        if (errors.length > 0) {
            setErrors(errors);
            return;
        }
        setButtonText(t('contact-section.sending-button'));
        const { name, phone, email, isRenting, operation, people, budget, pets, rooms, bathrooms, description, terms } = e.target.elements;
        let details = {
            nombre: name.value,
            telefono: phone.value,
            email: email.value,
            isRenting: isRenting.checked,
            operation: operation?.value,
            people: people?.value,
            budget: budget?.value,
            pets: pets?.value,
            rooms: rooms?.value,
            bathrooms: bathrooms?.value,
            mensaje: description.value,
            acepta_politica: terms.value,
            idioma: i18n.resolvedLanguage,
        };

        await submitData(details, e);
    }

    const submitData = (details:any, e:any) => {
        return new Promise((resolve, reject) => {
            Promise.resolve(sendContact(details))
                .then(response => {
                    if (response.success) {
                        setMessageSend(t('contact-section.email-ok'));
                        setErrors([]);
                        e.target.reset();
                        setIsRenting(false);
                        resolve(response);
                    } else {
                        setMessageError(t('contact-section.email-error'));
                        setErrors([]);
                        reject(response);
                    }
                    setButtonText(t('contact-section.send-button'));
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

  return (
      <section className="bg-white py-10 md:py-16">

          <div className="container max-w-screen-xl mx-auto px-4 xl:relative">

              <div className="bg-black bg-opacity-90 flex flex-col lg:flex-row items-center justify-evenly py-14 rounded-3xl">

                  <div data-aos={"fade-right"} className="text-center md:text-left mb-10 lg:mb-0">
                      <h2 className="font-semibold text-white text-4xl md:text-5xl lg:text-7xl leading-normal mb-4">
                          {parse(t('contact-section.contact-us'))}
                      </h2>

                      <p className="font-normal text-center md:text-left text-white text-md md:text-xl px-3 md:px-0">
                          {parse(t('contact-section.contact-us-text'))}
                      </p>
                      <div className="flex flex-col justify-start items-start mt-6 px-3 md:px-0">
                          <div className="flex justify-center items-center text-white space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                              </svg>
                              <span className="text-lg">971 347 752</span>
                          </div>
                          <div className="flex justify-center items-center text-white space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                              </svg>
                              <span className="text-lg">jorge@reesman.es</span>
                          </div>
                          <div className="flex justify-center items-center text-white space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                              </svg>
                              <span className="text-lg">San Antonio, Av. Doctor Fleming 47.</span>
                          </div>
                      </div>
                      <div className="flex justify-center items-center px-3 md:px-0 space-x-2 mt-6">
                          <a href="https://www.google.com/maps/place/Reesman+-+Servicios+Jur%C3%ADdicos+e+Inmobiliarios/@38.9705847,1.3102183,15z/data=!4m5!3m4!1s0x0:0x6bee7c03b8e63948!8m2!3d38.9705847!4d1.3102183"
                             target="_blank"
                             className="w-full py-4 px-6 font-semibold text-lg text-center text-gray-900 bg-white rounded-xl hover:bg-gray-300 transition ease-in-out duration-500">
                              {t('contact-section.button-location')}
                          </a>
                      </div>
                  </div>

                  <div className="xl:block absolute right-0">
                      <img src={`${process.env.PUBLIC_URL}/images/book.png`} alt="Image" />
                  </div>

                  <div data-aos={"fade-left"} className="md:block bg-white relative px-6 py-3 rounded-3xl w-full max-w-md">
                      <form onSubmit={handleSubmit}>
                          {errors && errors.length > 0 &&
                              <ul className="bg-red-100 list-disc list-inside mb-6 p-2 rounded-md">
                                  {errors.map((error: any) => (
                                      <li key={Math.random()} className="text-sm">{error}</li>
                                  ))}
                              </ul>
                          }

                          <div className="hidden">
                              <label htmlFor="_my_name_honeypot">Don't fill this out if you're human:</label>
                              <input type="text" id="_my_name_honeypot" name="_my_name_honeypot"/>
                          </div>

                          <div className="py-3 w-full">
                              <h3 className="font-semibold text-gray-900 text-3xl">Rellene el formulario</h3>
                          </div>

                          <div className="py-3 w-full">
                              <input type="text" name="name" placeholder={t('contact-section.name-input')}
                                     className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
                                     required/>
                          </div>

                          <div className="py-3 w-full">
                              <input type="text" name="phone" placeholder={t('contact-section.phone-input')}
                                     className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
                                     required/>
                          </div>

                          <div className="py-3 w-full">
                              <input type="text" name="email" placeholder={t('contact-section.email-input')}
                                     className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
                                     required/>
                          </div>

                          <div className="py-3 w-full">
                              <input type="checkbox" id="isRenting" name="isRenting" className="w-4 h-4 bg-gray-50 text-gray-500 rounded border border-gray-300 focus:ring-3 focus:ring-gray-300 dark:border-gray-600 dark:focus:ring-gray-600 dark:ring-offset-gray-800"
                                     checked={isRenting}
                                     onChange={handleCheckboxChange} />
                              <label htmlFor="isRenting" className="ml-2 text-sm text-gray-500">
                                  {t('contact-section.looking-for-rent')}
                              </label>
                          </div>

                          {isRenting && (
                              <div className="w-full">
                                  <div className="py-3">
                                      <div className="grid grid-cols-2 gap-x-1">
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.operation-input')}:</label>
                                              <select name="operation"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  {propertyStatusFormData.map(element => (
                                                      <option key={element.key}
                                                              value={element.value}>{element.value}</option>
                                                  ))}
                                              </select>
                                          </div>
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.people')}:</label>
                                              <select name="people"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                  <option value="5">5</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="py-3">
                                      <div className="grid grid-cols-2 gap-x-1">
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.budget')}:</label>
                                              <select name="budget"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  <option
                                                      value="800-1000">{t('contact-section.from')} 800 {t('contact-section.to')} 1000€
                                                  </option>
                                                  <option
                                                      value="1000-1500">{t('contact-section.from')} 1000 {t('contact-section.to')} 1500€
                                                  </option>
                                                  <option
                                                      value="1500-2500">{t('contact-section.from')} 1500 {t('contact-section.to')} 2500€
                                                  </option>
                                                  <option value="2500+">{t('contact-section.more_than')} 2500€</option>
                                              </select>
                                          </div>
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.pets')}:</label>
                                              <select name="pets"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  <option value="0">{t('contact-section.no')}</option>
                                                  <option value="1">{t('contact-section.yes')}</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="py-3">
                                      <div className="grid grid-cols-2 gap-x-1">
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.rooms')}:</label>
                                              <select name="rooms"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4+">4 {t('contact-section.or_more')}</option>
                                              </select>
                                          </div>
                                          <div className="w-full">
                                              <label className="text-sm">{t('contact-section.bathrooms')}:</label>
                                              <select name="bathrooms"
                                                      className="w-full bg-gray-100 rounded-xl outline-none px-4 py-3">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4+">4 {t('contact-section.or_more')}</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )}

                          <div className="py-3">
                              <textarea name="description"
                                        className="px-4 py-4 w-full h-52 bg-gray-100 placeholder-gray-400 rounded-xl outline-none peer resize-none"
                                        placeholder={t('contact-section.message')} required></textarea>
                          </div>

                          <div className="flex items-start mb-3">
                              <div className="flex items-center h-5">
                                  <input id="terms" aria-describedby="terms" type="checkbox"
                                         className="w-4 h-4 bg-gray-50 text-gray-500 rounded border border-gray-300 focus:ring-3 focus:ring-gray-300 dark:border-gray-600 dark:focus:ring-gray-600 dark:ring-offset-gray-800"
                                         required/>
                              </div>
                              <div className="ml-3 text-sm">
                                  <label htmlFor="terms"
                                         className="font-medium text-gray-500">{t('contact-section.agree')} <Link
                                      to={`/${currentLanguage}/privacy-policy`}
                                      className="text-blue-600 hover:underline dark:text-blue-500">{t('contact-section.terms-and-conditions-link')}</Link></label>
                              </div>
                          </div>

                          <div className="py-3">
                              <button
                                  className="w-full py-4 font-semibold text-lg text-white bg-gray-700 rounded-xl hover:bg-gray-900 transition ease-in-out duration-500">
                                  {buttonText}
                              </button>
                          </div>

                          <div className="py-3 w-full">
                              {messageSend.length > 0 &&
                                  <span className="mt-2 text-green-600">{messageSend}</span>
                              }
                              {messageError.length > 0 &&
                                  <span className="mt-2 text-red-500">{messageError}</span>
                              }
                          </div>
                      </form>
                  </div>

              </div>

          </div>

      </section>
  );
}

export default SectionContact;
